// Colors
$white: #fff;
$label-colour: #bbb;
$disabled-colour: #ddd;
//$toggle-colour: #2F855A;
$toggle-colour: $white;
$focus-color: #ff0;
//$switch-color: $white;
$switch-color-on: #00fb0e;
$switch-color-off: #b2b2b2;
$text-color: $label-colour;
$toggle-border-radius: 20px;

.toggle-switch {
    position: relative;
    margin-right: 10px;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    &-checkbox {
        display: none;
    }
    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid $label-colour;
        border-radius: $toggle-border-radius;
        margin: 0;
        &:focus {
            outline: none;
            > span {
                box-shadow: 0 0 2px 5px red;
            }
        }
        > span:focus {
            outline: none;
        }
    }
    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.1s ease-in 0s;
        &:before,
        &:after {
            display: block;
            float: left;
            width: 50%;
            height: 34px;
            padding: 0;
            line-height: 34px;
            font-size: 14px;
            color: white;
            font-weight: bold;
            box-sizing: border-box;
            //border: $switch-color-on solid;
            border-radius: $toggle-border-radius;
        }
        &:before {
            content: attr(data-yes);
            text-transform: uppercase;
            padding-left: 10px;
            background-color: $toggle-colour;
            color: $text-color;;
        }
    }
    &-disabled {
        background-color: $disabled-colour;
        cursor: not-allowed;
        &:before {
            background-color: $disabled-colour;
            cursor: not-allowed;
        }
    }
    &-inner:after {
        content: attr(data-no);
        text-transform: uppercase;
        padding-right: 10px;
        background-color: $white;
        color:$text-color;
        text-align: right;
    }
    &-switch {
        display: block;
        width: 24px;
        margin: 5px;
        background: $switch-color-off;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 40px;
        border: 0 solid $switch-color-off;
        border-radius: $toggle-border-radius;
        transition: all 0.1s ease-in 0s;
    }
    &-checkbox + &-label {
        border:$switch-color-off solid;
    }
    &-checkbox:checked + &-label {
        border: $switch-color-on solid;
        .toggle-switch-inner {
            margin-left: 0;
        }
        .toggle-switch-switch {
            right: 0px;
            background: $switch-color-on;
        }
    }
    &.small-switch {
        width: 40px;
        .toggle-switch-inner {
            &:after,
            &:before {
                content: "";
                height: 20px;
                line-height: 20px;
            }
        }
        .toggle-switch-switch {
            width: 16px;
            right: 20px;
            margin: 2px;
        }
    }
    @media screen and (max-width: 991px) {
        transform: scale(0.9);
    }
    @media screen and (max-width: 767px) {
        transform: scale(0.825);
    }
    @media screen and (max-width: 575px) {
        transform: scale(0.75);
    }
}
