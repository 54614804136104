.tab-list {
    padding: 0;

    li {
        list-style-type: none;

        button {
            text-align: left;
            width: 100%;
        }
    }
}

.bi {
    display: inline-block;
    width: 1rem;
    height: 1rem;
}