.ip-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background-color: white;
    margin: 0;
    padding: 0;
}

.ip-input {
    border: none;
    font-size: 20px;
    width: 45px;
    outline: none;
    background: linear-gradient(#000, #000) center bottom 5px / calc(100% - 10px) 2px no-repeat;
    /* text-decoration: underline; */
    margin: 0;
    text-align: center;
}

.ip-dot {
    font-size: 24px;
    color: black;
    margin: 0
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}