$onlineColor: rgba(92, 235, 44, 0.4);
$offlineColor: rgba(235, 44, 44, 0.4);
$onlineColorHover: green;
$offlineColorHover: darkred;

.status-container {
    width: 32px;
    color: white;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bubble {
    transition: 0.3s;
    position: absolute;
    cursor: pointer;

    &:hover:after {
    //    background-color: $onlineColorHover;
    }

    &:after {
        content: "";
   //     background-color: $onlineColor;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        display: block;
        top: 1px;
        left: 1px;
    }

    .bubble-outer-dot {
        margin: 1px;
        display: block;
        text-align: center;
        opacity: 1;
    //    background-color: $onlineColor;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        -webkit-animation: bubble-pulse 1.5s linear infinite;
        -moz-animation: bubble-pulse 1.5s linear infinite;
        -o-animation: bubble-pulse 1.5s linear infinite;
        animation: bubble-pulse 1.5s linear infinite
    }

    .bubble-inner-dot {
        display: block;
        text-align: center;
        opacity: 1;
   //     background-color: $onlineColor;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        -webkit-animation: bubble-pulse 1.5s linear infinite;
        -moz-animation: bubble-pulse 1.5s linear infinite;
        -o-animation: bubble-pulse 1.5s linear infinite;
        animation: bubble-pulse 1.5s linear infinite;

        &:after {
            content: "";
            display: block;
            text-align: center;
            opacity: 1;
        //    background-color: $onlineColor;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            -webkit-animation: bubble-pulse 1.5s linear infinite;
            -moz-animation: bubble-pulse 1.5s linear infinite;
            -o-animation: bubble-pulse 1.5s linear infinite;
            animation: bubble-pulse 1.5s linear infinite
        }
    }

    @-webkit-keyframes bubble-pulse {
        0% {
            transform: scale(1);
            opacity: .75
        }
        25% {
            transform: scale(1);
            opacity: .75
        }
        100% {
            transform: scale(2.5);
            opacity: 0
        }
    }

    @keyframes bubble-pulse {
        0% {
            transform: scale(1);
            opacity: .75
        }
        25% {
            transform: scale(1);
            opacity: .75
        }
        100% {
            transform: scale(2.5);
            opacity: 0
        }
    }

    @-moz-keyframes bubble-pulse {
        0% {
            transform: scale(1);
            opacity: .75
        }
        25% {
            transform: scale(1);
            opacity: .75
        }
        100% {
            transform: scale(2.5);
            opacity: 0
        }
    }

    @-o-keyframes bubble-pulse {
        0% {
            transform: scale(1);
            opacity: .75
        }
        25% {
            transform: scale(1);
            opacity: .75
        }
        100% {
            transform: scale(2.5);
            opacity: 0
        }
    }
}

.bubble-online {
    &:hover:after {
        background-color: $onlineColorHover;
    }

    &:after {
        background-color: $onlineColor;
    }

    .bubble-outer-dot {
        background-color: $onlineColor;
    }

    .bubble-inner-dot {
        background-color: $onlineColor;

        &:after {
            background-color: $onlineColor;
        }
    }
}

.bubble-offline {
    &:hover:after {
        background-color: $offlineColorHover;
    }

    &:after {
        background-color: $offlineColor;
    }

    .bubble-outer-dot {
        background-color: $offlineColor;
    }

    .bubble-inner-dot {
        background-color: $offlineColor;

        &:after {
            background-color: $offlineColor;
        }
    }
}
